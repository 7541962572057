import React from "react"
export default () => (
  <header>
    <div class="container">
      <p id="site-name"><a href="/">Tatsu Johnson</a></p>
      <nav>
        <ul>

        </ul>
      </nav>
    </div>
  </header>

)